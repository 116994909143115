import React from 'react';
import PropTypes from 'prop-types';
import './banner.scss';

const Banner = ({ direction, className, children }) => (
	<div className={`Banner ${direction} ${className}`}>{children && children}</div>
);

Banner.defaultProps = {
	direction: 'horizontal'
};
Banner.propTypes = {
	direction: PropTypes.oneOf(['horizontal', 'vertical'])
};

export default Banner;
