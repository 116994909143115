import React from 'react';
import './loader.scss';
// import PropTypes from 'prop-types';

const Loader = ({ title }) => (
	<div className="Loader">
		{title && <h3 className="Loader_title">{title}</h3>}
		<div className="Loader_spinner"/>
	</div>
);

Loader.defaultProps = {};
Loader.propTypes = {};

export default Loader;
