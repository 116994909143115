import { compose, lifecycle, withState } from 'recompose';
import Counter from './counter';

const randomNumber = (start, callBack, options = {}) => {
	let defaultOptions = {
		min: 1,
		max: 1,
		interval: 5000,
		...options
	};

	setInterval(function() {
		const random = Math.floor(Math.random() * (+defaultOptions.max - +defaultOptions.min)) + +defaultOptions.min;
		start = start + random;

		return callBack(start);
	}, defaultOptions.interval);
};

const enhance = compose(
	withState('population', 'setPopulation', ({data}) => data.population),
	withState('recovered', 'setRecovered', ({data}) => data.recovered),
	withState('death', 'setDeath', ({data}) => data.death),
	withState('cases', 'setCases', ({data}) => data.cases),
	lifecycle({
		componentDidMount() {
			const { setCases, cases, death, setDeath, recovered, setRecovered, population, setPopulation } = this.props;

			randomNumber(cases, setCases);
			randomNumber(death, setDeath, {interval: 15000, max: 1});
			randomNumber(recovered, setRecovered, {interval: 30000, max: 1});
			randomNumber(population, setPopulation, {interval: 500, min: -5, max: 5});
		}
	})
);
export default enhance(Counter);
