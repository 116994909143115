import React from 'react';
import MapImage from '../../assets/map.png'
import CarantinGif from '../../assets/carantin.gif';
import './map.scss';

const Map = ({ className }) => (
	<div className={`Map ${className}`}>
		<div className="Map_left">
			<h3>Карта поширення вірусу</h3>
			<a
				target="_blank"
				href="https://www.google.com/maps/d/u/0/viewer?mid=1yCPR-ukAgE55sROnmBUFmtLN6riVLTu3&ll=48.511424955916645%2C38.70498797821182&z=5"
			>
				<span className="Map_btn">Показати карту</span>
				<img src={MapImage} alt="map" className="Map_img" />
			</a>
		</div>
		<div className="Map_right">
			<h3>Чому важливий карантин</h3>
			<img src={CarantinGif} alt="map" className="Map_gif" />
		</div>
	</div>
);

export default Map;
