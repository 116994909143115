import React from 'react';
import { Counter, Loader, Map, Banner } from '../';
import './App.scss';

const App = ({ data, error }) => (
  <div className="App">
    {
      data ? (
        <div className="grid">
          <Banner className="grid_top">
            <a
              target="_blank"
              rel="nofollow"
              href="https://ad.admitad.com/g/jncnciosyma3a55245f9fbdf0436a5/?i=4"
            >
              <img
                width="100%"
                border="0"
                src="https://ad.admitad.com/b/jncnciosyma3a55245f9fbdf0436a5/"
                alt="Rozetka UA"
              />
            </a>
          </Banner>

          <h1 className="grid_title">
            <span className="uppercase">covid 19 coronavirus</span>
            <span>Статистика захворювань короновірусу онлайн</span>
          </h1>

          <Counter className="grid_counter" data={data} />

          <Map className="grid_map" />

          <p className="grid_bottomText">
            * дані беруться з відкритих джерел та є приблизними.
            При використанні сайту, посилання на ресурс
            <a href="http://coronka.online">coronka.online</a>
            обов’язкове.
          </p>
        </div>
      ) : (
        error ? JSON.stringify(error) : <Loader title="Please wait..." />
      )
    }
  </div>
);

export default App;
