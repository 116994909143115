import axios from 'axios';

const getDataFromWeb = async () => {
	const result = await axios.get(process.env.REACT_APP_API_URL, {
		headers: {
			'Access-Control-Allow-Origin': '*',
			"Access-Control-Allow-Headers": "X-Requested-With"
		}
	});
	return result.data;
};

export {
	getDataFromWeb,
}
