import { compose, lifecycle, withState } from 'recompose';
import App from './app';
// import writeJsonFile  from 'write-json-file';
import { getDataFromWeb } from '../../utils/queryHelper';

const getData = async (resolve, reject) => {
	try {
		const { cases, death, recovered, population } = await getDataFromWeb();

		resolve({ cases, death, recovered, population });
	} catch (e) {
		reject(e.message);
	}
};

const enhance = compose(
	withState('data', 'setData', null),
	withState('error', 'setError', null),
	lifecycle({
		async componentDidMount() {
			const { setData, setError } = this.props;

			await getData(data => setData(data), err => setError(err));
		}
	})
);
export default enhance(App);
