import React from 'react';
import PropTypes from 'prop-types';
import CasesImg from '../../assets/cases.png'
import DeathImg from '../../assets/death.png'
import RecoveredImg from '../../assets/recovered.png'
import './counter.scss';

const Counter = ({ className, population, cases, death, recovered }) => (
	<div className={`${className} Counter`}>
		<div className="Counter_wrap">
			<p className="column-center">
				<span className="title">Загальне населення планети:</span>
				<span className="value black">{population}</span>
			</p>
			<div className="row-between w-100 w-max">
				<p>
					<span className="title">
						Захворіло загалом:
						<img src={CasesImg} alt="cases"/>
					</span>
					<span className="value blue">{cases}</span>
				</p>
				<p>
					<span className="title">
						Смертей загалом:
						<img src={DeathImg} alt="cases"/>
					</span>
					<span className="value red">{death}</span>
				</p>
			</div>
			<p className="column-center">
				<span className="title">
					Вилікувалось загалом:
					<img src={RecoveredImg} alt="recovered"/>
				</span>
				<span className="value green">{recovered}</span>
			</p>
		</div>
	</div>
);

Counter.defaultProps = {
	randomNumber: 0
};
Counter.propTypes = {
	data: PropTypes.shape({
		cases: PropTypes.number,
		death: PropTypes.number,
		recovered: PropTypes.number
	})
};

export default Counter;
